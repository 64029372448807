#benefits-page{
  @media screen and (min-width: 768px){
    &.benefits-results-page{
      padding-top: 17px;
    }
  }

  .banner-section{
    position: relative;
    width: 100%;
    height: 83vh;
    background-color: $soft-gray;

    .container{
      height: 100%;

      >.row{
        height: 100%;
      }

      .box-search-benefit{
        position: relative;
        display: inline-block;
        width: 100%;

        &:before{
          content: " ";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $blue;
          border-radius: 50px;
          box-shadow: 0 0 9px rgba(0,0,0,0.4);
        }

        .box{
          position: relative;
          width: 100%;
          padding: 90px 85px 100px 85px;
          z-index: 5;

          .title{
            color: #ffffff;
            font-size: 3.0rem;
            text-align: center;
            text-transform: uppercase;
          }

          .txt-top{
            color: #ffffff;
            text-align: center;
            font-size: 1.05rem;
            font-weight: 500;
          }

          form{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            border-bottom: 1px solid #ffffff;
            @include placeholder-color(#ffffff);

            .custom-select{
              margin-right: 5px;
              width: 155px;
              height: 43px;
              color: #ffffff;
              cursor: pointer;
              border: 0;
              border-radius: 0;
              background-color: transparent;
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");

              option{
                color: $gray !important;
                font-weight: 500;
              }
            }

            .form-control{
              display: flex;
              height: 43px;
              color: #ffffff;
              font-weight: 500;
              border: 0;
              border-radius: 0;
              background-color: transparent;
            }

            .btn-search{
              display: inline-block;
              padding: 0.6rem 0.75rem;
              width: 210px;
              height: 43px;
              color: #ffffff;
              font-weight: 500;
              border: 0 !important;
              border-radius: 0 !important;
              background-color: $orange;

              &:hover, &:active, &:focus{
                background-color: $orange2 !important;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px){
      .container{
        .box-search-benefit{
          .box{
            form{
              border-bottom: 0;

              .form-control{
                flex: 0 0 100%;
                max-width: 100%;
                border-bottom: 1px solid #fff;
              }
              .custom-select{
                width: 50%;
              }
              .btn-search{
                width: 48%;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 575px){
      .container{
        .box-search-benefit{
          &:before{
            border-radius: 24px;
          }

          .box{
            padding: 80px 40px 90px 40px;

            .title{
              font-size: 2.6rem;
              line-height: 1.0;
            }

            .txt-top{
              font-size: 0.95rem;
              line-height: 1.3;
              text-align: justify;
            }

            form{
              .form-control{
                padding-left: 4px;
                padding-right: 4px;
              }
              .custom-select{
                margin-right: 0;
                width: 100%;
                padding-left: 0;
                padding-right: 0;
              }
              .btn-search{
                width: 100%;
              }
            }
          }
        }
      }
    }

    // Pagina de busqueda
    &.search-section{
      height: inherit;
      background-image: none !important;
      background-color: $blue;

      .container{
        .box-search-benefit{
          &:before{
            display: none;
          }

          .box{
            padding: 12px 0 20px 0;

            .title{
              font-size: 1.55rem;
              font-weight: 600;
            }

            form{
              margin-top: 10px;

              .form-control{
                padding-left: 0;
              }

              .select-category{
                width: 215px;
              }
            }
          }
        }
      }

      @media screen and (max-width: 991px){
        .container{
          .box-search-benefit{
            .box{
              form{
                border-bottom: 0;

                .form-control{
                  flex: 0 0 100%;
                  max-width: 100%;
                  border-bottom: 1px solid #ffffff;
                }

                .custom-select, .btn-search{
                  margin: 0;
                  width: 33.30%;
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: 767px){
        .container{
          .box-search-benefit{
            .box{
              form{
                .custom-select{
                  width: 50%;
                }
                .btn-search{
                  width: 100%;
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: 575px){
        .container{
          .box-search-benefit{
            .box{
              form{
                .custom-select{
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .info-section{
    padding-top: 30px;
    color: #403f3f;
    font-size: 0.95rem;
    line-height: 1.25;
    text-align: justify;

    ol, ul{
      padding-left: 15px;
    }

    a{
      color: $orange;
    }

    @media screen and (max-width: 575px){
      padding-top: 16px;
    }
  }

  .buttons-section{
    padding-top: 40px;
    padding-bottom: 40px;

    .col-button{
      .btn-big{
        @include flex-center-xy();
        padding: 20px 28px;
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        height: 220px;
        color: #ffffff;
        cursor: pointer;
        text-align: center;
        text-decoration: none !important;
        border-radius: 32px;
        box-shadow: 0 1px 7px rgba(0, 0, 0, 0.6);

        &.blue{
          background-color: $blue;

          &:hover{
            background-color: $blue2;
          }
        }
        &.orange{
          background-color: $orange;

          &:hover{
            background-color: $orange2;
          }
        }
        &.green{
          background-color: $green;

          &:hover{
            background-color: $green2;
          }
        }
        &.pink{
          background-color: $pink;

          &:hover{
            background-color: $pink2;
          }
        }

        .inside{
          display: inline-block;
          width: 100%;
          font-size: 1.70rem;
          font-weight: 600;
          line-height: 1.3;
        }
      }
    }

    @media screen and (max-width: 1499px){
      .col-button{
        .btn-big{
          height: 170px;

          .inside{
            font-size: 1.45rem;
          }
        }
      }
    }
    @media screen and (max-width: 991px){
      padding-top: 20px;
      padding-bottom: 20px;

      .col-button{
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
    @media screen and (max-width: 575px){
      padding-top: 12px;
      padding-bottom: 12px;

      .col-button{
        padding-top: 8px;
        padding-bottom: 8px;

        .btn-big{
          height: 80px;
          border-radius: 20px;

          .inside{
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  .promotions-section{
    .states-container{
      .col-state{
        a{
          display: inline-block;
          padding: 16px 15px;
          width: 100%;
          color: $orange;
          cursor: pointer;
          font-size: 1.5rem;
          font-weight: 600;
          text-align: center;
          text-decoration: none !important;
          text-transform: uppercase;
          border-radius: 24px 24px 0 0;
          background-color: #ededed;

          &.active{
            color: #ffffff !important;
            background-color: #f2b53c !important;
          }
        }
      }

      @media screen and (max-width: 1399px){
        .col-state{
          a{
            font-size: 1.35rem;
          }
        }
      }
      @media screen and (max-width: 767px){
        .col-state{
          a{
            padding: 16px 15px 12px 15px;
            font-size: 0.95rem;
            font-weight: 700;
            border-radius: 12px 12px 0 0;
          }
        }
      }
    }

    .categories-container{
      position: relative;
      padding: 24px 0 20px 0;
      background-color: #ededed;

      .form-group{
        margin-bottom: 0;

        .box-categories{
          .custom-radio{
            padding: 0;
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 5px;

            .custom-control-label{
              .btn-category{
                display: inline-block;
                padding: 9px 20px 8px 20px;
                width: 100%;
                color: #ffffff;
                cursor: pointer;
                font-size: 0.95rem;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                text-decoration: none !important;
                background-color: $blue;
                border-radius: 8px;
              }

              &:before, &:after{
                display: none !important;
              }
            }

            .custom-control-input:checked ~ .custom-control-label{
              .btn-category{
                background-color: #026d9b;
              }
            }
          }
        }
      }

      @media screen and (max-width: 575px){
        padding: 16px 0 16px 0;

        .form-group{
          .box-categories{
            .custom-radio{
              margin-top: 4px;
              margin-bottom: 4px;
              margin-right: 4px;

              .custom-control-label{
                .btn-category{
                  font-size: 0.85rem;
                }
              }
            }
          }
        }
      }
    }

    .results-container{
      position: relative;
      padding: 0 0 20px 0;
      background-color: #ededed;

      .col-title{
        margin-bottom: 15px;
      }

      .col-results{
        .box{
          display: flex;
          flex: 0 0 100%;
          max-width: 100%;
          flex-wrap: wrap;
          margin-bottom: 30px;

          .col-image{
            padding-left: 0;

            .image{
              display: inline-block;
              width: 100%;
              height: 100%;
              min-height: 170px;
              background-color: #fff;
            }
          }

          .col-info{
            padding: 8px 0 8px 20px;

            .title{
              font-size: 1.45rem;
              font-weight: 700;
              text-transform: uppercase;
            }

            .promo{
              color: $orange;
              font-size: 1.25rem;
              font-weight: 600;
            }

            .descr{
              display: inline-block;
              margin-top: 15px;
              margin-bottom: 20px;
              width: 100%;
              line-height: 1.15;
            }

            .validity-promo{
              font-size: 1.10rem;
              font-weight: 600;
            }

            .url-promo{
              display: inline-block;
              margin-top: 8px;

              a{
                color: $gray;
              }
            }
          }
        }
      }

      @media screen and (max-width: 991px){
        .col-results{
          .box{
            margin-bottom: 12px;

            .col-image{
              padding: 0;

              .image{
                min-height: 210px;
              }
            }

            .col-info{
              padding: 10px 0 10px 0;
            }
          }
        }
      }
      @media screen and (max-width: 575px){
        .col-results{
          .box{
            .col-info{
              .title{
                font-size: 1.25rem;
              }

              .promo{
                font-size: 1.05rem;
              }

              .descr{
                font-size: 0.92rem;
              }

              .validity-promo{
                font-size: 0.95rem;
              }

              .url-promo{
                font-size: 0.95rem;
              }
            }
          }
        }
      }
    }
  }
}
