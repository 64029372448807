#header{
	position: relative;
	width: 100%;
	height: 84px;
	z-index: 50;

	@media screen and (max-width: 1199px){
		height: 68px;
	}
	@media screen and (max-width: 991px){
		height: 56px;
	}

	.header-content{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
	}

	.navbar{
		background-color: #ffffff !important;

		.navbar-brand{
			img{
				max-height: 58px;
			}
		}

		.navbar-toggler{
			padding: 0.25rem 0.65rem;
			border: 1px solid $orange;

			.navbar-toggler-icon{
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28222, 114, 34, 0.99%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
			}
		}

		#nav-collapse{
			.navbar-nav{
				.nav-simple{
					.nav-link{
						color: #565656;
						font-weight: 600;
						cursor: pointer;

						.icon{
							position: relative;
							margin-left: 3px;
							top: 2px;
							font-size: 125%;
						}
					}
				}
				.nav-home{}
				.nav-green{
					.nav-link{
						color: $green2;
					}
				}

				.b-nav-dropdown{
					.dropdown-menu{
						padding: 0;
						border: 0;
						border-radius: 0;
						border-top: 1px solid #84d3f4;
						border-bottom: 1px solid #84d3f4;

						li{
							.dropdown-item{
								padding: 0.30rem 1.7rem;
								color: #ffffff;
								text-align: center;
								font-size: 0.95rem;
								border-bottom: 1px solid #bfe5f5;
								border-right: 1px solid #84d3f4;
								border-left: 1px solid #84d3f4;
								background-color: $blue;

								&.location{
									border-right-color: #91ee2c;
									border-left-color: #91ee2c;
									background-color: $green;
								}
							}

							&:last-child{
								.dropdown-item{
									border-bottom: 0;
								}
							}
						}
					}
				}

				.nav-networks{
					line-height: 1;

					.nav-link{
						a{
							display: inline-block;
							margin: 0 4px;
							color: #565656;
							font-size: 1.6rem;
						}
					}
				}
			}
		}
	}

	// == Fixed Buttons ==
	.box-fixed-buttons{
		position: relative;

		.sp-buttons-section{
			position: absolute;
			top: 12px;
			left: 0;
			width: 100%;
			z-index: 30;

			.col-button{
				a{
					display: inline-block;
					padding: 12px 10px;
					width: 100%;
					color: #ffffff;
					cursor: pointer;
					font-weight: 600;
					text-align: center;
					text-decoration: none !important;
					border-radius: 25px;
					box-shadow: 0px 0 6px rgba(0,0,0,0.16);

					&.membership{
						background-color: $green;

						&:hover{
							background-color: $green2;
						}
					}
					&.plan{
						background-color: $blue;

						&:hover{
							background-color: $blue2;
						}
					}
					&.easylovers{
						background-color: $pink;

						&:hover{
							background-color: $pink2;
						}
					}
				}
			}

			@media screen and (max-width: 991px){
				.container{
					max-width: 100%;
				}
			}
			@media screen and (max-width: 767px){
				display: none;
			}
		}
	}
	// == ==

	@media screen and (min-width: 992px){
		@media screen and (max-width: 1199px){
			.navbar{
				.navbar-brand{
					img{
						max-height: 42px;
					}
				}

				#nav-collapse{
					.navbar-nav{
						.nav-simple{
							.nav-link{
								font-size: 0.96rem;
							}
						}

						.nav-home, .nav-networks{
							display: none;
						}
					}
				}
			}
		}
	}
	@media screen and (min-width: 1200px){
		.navbar{
			.nav-simple{
				margin-left: 0.45vw;
				margin-right: 0.45vw;
			}
		}
	}
	@media screen and (min-width: 1300px){
		.navbar{
			.nav-simple{
				margin-left: 1.0vw;
				margin-right: 1.0vw;
			}
		}
	}
	@media screen and (min-width: 1800px){
		.navbar{
			.nav-simple{
				margin-left: 1.5vw;
				margin-right: 1.5vw;
			}
		}
	}
	@media screen and (max-width: 991px){
		.navbar{
			.navbar-brand{
				padding-top: 0;
				padding-bottom: 0;

				img{
					max-height: 35px;
				}
			}

			#nav-collapse{
				.navbar-nav{
					.nav-simple{
						.nav-link{
							padding: 0.25rem 0.4rem;
						}
					}

					.nav-networks{
						margin-top: 4px;
						text-align: center;

						.nav-link{
							padding-bottom: 4px;
							padding-top: 4px;

							a{
								margin: 0 9px;
								color: $orange;
							}
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 767px){
		.navbar{
			#nav-collapse{
				.navbar-nav{
					.nav-simple{
						text-align: center;
					}
				}
			}
		}
	}
}
