#card-use-page{
  padding-top: 15px;
	padding-bottom: 30px;

  .main-section{
    .col-card{
      padding-top: 28px;
      margin-top: 35px;
      margin-bottom: 35px;
      text-align: center;

      .box{
        position: relative;
        display: inline-block;
        padding: 68px 20px 46px 20px;
        width: 360px;
        max-width: 100%;
        height: 100%;
        color: #ffffff;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.3;
        border-radius: 28px;
        background-color: $orange;

        .num{
          position: absolute;
          display: inline-block;
          margin-left: -60px;
          padding: 2px 12px;
          top: -26px;
          left: 50%;
          min-width: 120px;
          color: $green;
          font-size: 4.2rem;
          font-weight: 700;
          line-height: 1;
          border: 4px solid $green;
          border-radius: 18px;
          background-color: #ffffff;
        }

        .inside{
          @include flex-center-xy();
          flex: 0 0 100%;
          height: 100%;

          span{
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 767px){
      .col-card{
        margin-top: 20px;
        margin-bottom: 10px;

        .box{
          width: 100%;
        }
      }
    }
  }

}
