#concept-page-v2{
  padding-top: 15px;
	padding-bottom: 30px;

  .main-section{
    .col-title{
      margin-bottom: 5px;
    }

    .results-container{
      position: relative;
      padding: 0 0 20px 0;

      .col-results{
        .box{
          display: flex;
          flex: 0 0 100%;
          max-width: 100%;
          flex-wrap: wrap;
          margin-bottom: 30px;

          .col-image{
            padding-left: 0;

            .image{
              display: inline-block;
              width: 100%;
              height: 100%;
              min-height: 170px;
              box-shadow: 0 0 2px rgba(0,0,0,0.45);
              background-color: $soft-gray;
            }
          }

          .col-info{
            padding: 8px 0 8px 20px;

            .title{
              font-size: 1.40rem;
              font-weight: 700;
              text-transform: uppercase;
            }

            .promo{
              color: $orange;
              font-size: 1.25rem;
              font-weight: 600;
            }

            .descr{
              display: inline-block;
              margin-top: 5px;
              margin-bottom: 14px;
              width: 100%;
              line-height: 1.15;
            }

            .validity-promo{
              font-size: 1.10rem;
              font-weight: 600;
            }

            .url-promo{
              display: inline-block;
              margin-top: 8px;

              a{
                color: $gray;
              }
            }
          }
        }
      }

      @media screen and (max-width: 991px){
        .col-results{
          .box{
            margin-bottom: 12px;

            .col-image{
              padding: 0;

              .image{
                min-height: 210px;
              }
            }

            .col-info{
              padding: 10px 0 10px 0;
            }
          }
        }
      }
      @media screen and (max-width: 575px){
        .col-results{
          .box{
            .col-info{
              .title{
                font-size: 1.25rem;
              }

              .promo{
                font-size: 1.05rem;
              }

              .descr{
                font-size: 0.92rem;
              }

              .validity-promo{
                font-size: 0.95rem;
              }

              .url-promo{
                font-size: 0.95rem;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 575px){
      .col-title{
        margin-bottom: 0;
      }
    }
  }

}
