.loading-s1-component{
  position: relative;
  @include flex-center-xy();
  width: 100%;
  height: 90vh;

  .box{
    display: inline-block;
    width: 100%;
    text-align: center;

    img{
      max-width: 100%;
    }
  }
}
