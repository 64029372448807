#footer{
  position: relative;
  width: 100%;

  // == Newsletter ==
	.newsletter-section{
		position: relative;
		padding: 24px 15px 33px 15px;
		width: 100%;
		background-color: $blue;

		h2{
			color: #ffffff;
			font-size: 1.9rem;
			text-align: center;
		}

		form{
			margin-top: 18px;
			text-align: center;
			@extend .flex-center-xy;
			flex-wrap: wrap;

			.form-group{
				width: 600px;
				margin-bottom: 0;
				@include placeholder(#ffffff !important, 400, normal, 1.1rem);

				>div{
					width: 100%;
				}

				.form-control{
					height: 46px;
          color: #ffffff;
					text-align: center;
					font-size: 1.1rem;
					border-radius: 10px;
					border-color: #ffffff;
					background-color: transparent;
				}
			}

			.btn-send{
				position: relative;
				padding-bottom: 8px;
				margin-left: -15px;
				width: 155px;
				height: 46px;
				color: #ffffff;
				font-size: 1.05rem;
				font-weight: 700;
				text-align: center;
				background-color: $orange;
				border-color: $orange;
				border-radius: 10px;

				&:hover{
					border-color: $orange2;
					background-color: $orange2;
				}
			}
		}

		@media screen and (max-width: 991px){
			form{
				.form-group{
					width: calc(90vw - 155px);
				}
			}
		}
		@media screen and (max-width: 575px){
			h2{
				font-size: 1.5rem;
				line-height: 1.1;
			}

			form{
				.form-group{
					width: calc(100% - 127px);
					@include placeholder(#919293, 400, normal, 1.0rem);

					.form-control{
						height: 38px;
						font-size: 1.0;
					}
				}

				.btn-send{
					width: 127px;
					height: 38px;
					font-size: 1.0rem;
				}
			}
		}
	}
	// == ==

  // == Footer section ==
  .footer-section{
    padding-top: 22px;
    padding-bottom: 17px;
    color: #ffffff;
    font-size: 0.90rem;
    background-color: $orange;

    a{
      display: inline-block;
      color: #ffffff;
    }

    h6{
      margin-bottom: 8px;
      color: #ffffff;
      font-size: 0.91rem;
      font-weight: 400;
    }

    .col-footer{
      margin-top: 5px;
      margin-bottom: 5px;

      &.col-r{
        text-align: right;
      }

      .fa-whatsapp{
        font-size: 120%;
      }
    }

    .col-logo{
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;

      img{
        max-width: 96%;
      }
    }

    .col-networks{
      text-align: center;

      .link{
        display: inline-block;
        margin: 2px 12px;
        color: rgba(255,255,255,0.5);
        font-size: 1.95rem;

        &:hover{
          color: rgba(255,255,255,0.8);
        }
      }

      .at{
        display: inline-block;
        color: #050302;
        font-size: 1.3rem;
        font-weight: 600,
      }

      .hashtag{
        display: inline-block;
        color: #050302;
        font-size: 1.55rem;
        font-weight: 600;

        .pi{
          color: #e5007e;
        }
        .bl{
          color: #009ee2;
        }
        .pu{
          color: #662482;
        }
      }

      .at, .hashtag{
        margin: 3px 8px;
      }
    }

    .col-copyright{
      position: relative;
      padding-top: 14px;
      margin-top: 6px;
      text-align: center;

      &:before{
        content: " ";
        position: absolute;
        top: 0;
        left: 15px;
        width: calc(100% - 30px);
        border-top: 1px solid rgba(255,255,255,0.9);
      }
    }
  }

  @media screen and (min-width: 992px){
    .footer-section{
      >.row{
        -ms-flex-align: center !important;
        -webkit-box-align: center !important;
        align-items: center !important;
      }

      .col-logo{
        flex: 0 0 310px;
        max-width: 310px;
      }
    }
  }
  @media screen and (max-width: 991px){
    font-size: 0.85rem;

    .footer-section{
      .col-footer{
        margin-top: 7px;
        margin-bottom: 7px;
        text-align: center !important;
        line-height: 1.3;
      }

      .col-logo{
        margin-top: 15px;
        order: 5;

        img{
          width: 305px;
        }
      }

      .col-networks{
        order: 7;
      }
    }
  }
  @media screen and (max-width: 575px){
    .footer-section{
      padding-top: 15px;

      h6{
        margin-bottom: 0;
      }

      .col-logo{
        margin-top: 7px;

        img{
          width: 265px;
        }
      }

      .col-networks{
        .link{
          line-height: 1;
        }

        .hashtag{
          font-size: 1.45rem;
        }

        .at, .hashtag{
          margin: 0 8px;
        }
      }
    }
  }

}
