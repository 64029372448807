.btn-s-1{
  display: inline-block;
  color: #ffffff !important;
  font-weight: 500;
  border-radius: 0.4rem;

  &.blue{
    border-color: $blue;
    background-color: $blue;

    &:hover, &:active, &:focus{
      border-color: $blue2;
      background-color: $blue2;
    }
  }

  &.green{
    border-color: $green;
    background-color: $green;

    &:hover, &:active, &:focus{
      border-color: $green2;
      background-color: $green2;
    }
  }

  &.btn-lg{
    padding: 0.78rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 600;
  }
}
