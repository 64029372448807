#contact-page{
  padding-top: 15px;
	padding-bottom: 45px;

  .main-section{
    .col-title{
      margin-bottom: 5px;
    }

    .col-form{
      .btn-send{
        width: 260px;
        max-width: 100%;
      }
    }

    .col-map{
      iframe{
        width: 100%;
        height: 100%;
      }

      .vue-map-container{
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (min-width: 1400px){
      .col-form{
        padding-right: 40px;
      }
    }
    @media screen and (max-width: 991px){
      .col-map{
        iframe, .vue-map-container{
          height: 300px;
        }
      }
    }
  }

  .branch-offices-section{
    margin-top: 30px;

    .row-content{
      margin-bottom: 15px;

      &:last-child{
        margin-bottom: 0;
      }
    }

    .col-title{
      .title-s-1{
        margin-bottom: 5px;
        font-size: 1.95rem;
      }
    }

    .col-branch-office{
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px;
      margin-bottom: 25px;

      .col-image{
        padding: 0;
        flex: 0 0 52%;
        max-width: 52%;

        .box-image{
          display: block;
          height: 100%;
          background-color: $soft-gray;
        }
      }

      .col-info{
        flex: 0 0 48%;
        max-width: 48%;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 0;
        font-size: 0.8rem;
        line-height: 1.4;
        text-align: center;

        .title{
          margin-bottom: 7px;
          color: $orange;
          font-size: 1.20rem;
          font-weight: 700;
          text-transform: uppercase;
        }

        .subtitle{
          color: $orange;
          font-size: 0.90rem;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }

    @media screen and (min-width: 1600px){
      .col-branch-office{
        .col-info{
          flex: 0 0 38%;
          max-width: 38%;
        }
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1199px){
      max-width: 100%;

      .col-branch-office{
        .col-image{
          flex: 0 0 50%;
          max-width: 50%;
        }

        .col-info{
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
    @media screen and (max-width: 991px){
      .col-branch-office{
        margin-top: 10px;
        margin-bottom: 15px;
      }
    }
    @media screen and (max-width: 575px){
      .col-branch-office{
        .col-image{
          flex: 0 0 45%;
          max-width: 45%;
        }

        .col-info{
          flex: 0 0 55%;
          max-width: 55%;
          line-height: 1.2;

          .title{
            margin-bottom: 3px;
            font-size: 1.1rem;
            font-weight: 800;
          }
          .subtitle{
            font-size: 0.85rem;
          }
        }
      }
    }
  }

}
