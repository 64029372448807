#promo-description-page{
	.description-section{
		position: relative;

		.class-image{
			position: absolute;
			top: 0;
			left: 0;
			width: calc(58.33% - 40px);
			height: 100%;
			background-color: $soft-gray;
		}

		.col-images{
			padding-top: 35px;

			.title{
				color: #ffffff;
				font-size: 2.35rem;
				font-weight: 700;
				text-shadow: 1px 1px 2px rgba(0,0,0,0.35);
			}
		}

		.col-info{
			padding-top: 35px;
			padding-bottom: 35px;
			min-height: 65vh;

			.title{
				position: relative;
				color: $blue;
				font-size: 2.15rem;
				font-weight: 700;
				line-height: 1.05;
				overflow: hidden;

				span{
					position: relative;
					display: inline-block;
					padding-bottom: 6px;

					&:after{
						content: " ";
						position: absolute;
						bottom: 0;
						left: 0;
						width: 270%;
						border-top: 4px solid $blue;
					}
				}
			}

			.subcategory{
				color: $gray;
				font-size: 1.20rem;
				font-weight: 600;
				line-height: 1.1;
			}

			.descr{
				display: inline-block;
				width: 100%;
				margin-bottom: 2px;
				color: $orange;
				font-size: 0.98rem;
				font-weight: 500;
				line-height: 1.20;
			}

			.box-buttons{
				display: inline-block;
				width: 100%;
				margin-top: 15px;

				a{
					display: block;
					padding: 10px 15px;
					margin-top: 10px;
					margin-bottom: 10px;
					color: #ffffff;
					font-size: 1.1rem;
					font-weight: 500;
					text-align: center;
					text-decoration: none !important;
					cursor: pointer;

					&.btn-schedule{
						background-color: $blue;

						&:hover{
							background-color: $blue2;
						}
					}
					&.btn-inscription{
						background-color: $green;

						&:hover{
							background-color: $green2;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: 768px){
		.description-section{
			.col-images, .col-info{
				padding-top: 75px;
			}
		}
	}
	@media screen and (max-width: 991px){
		.description-section{
			.class-image{
				width: calc(50% - 20px);
			}
		}
	}
	@media screen and (max-width: 767px){
		.description-section{
			.class-image{
				width: 100%;
				height: 45vh;
			}

			.col-images{
				padding-top: 20px;
				height: 45vh;
			}

			.col-info{
				padding-top: 16px;
				padding-bottom: 16px;
			}
		}
	}
	@media screen and (max-width: 575px){
		.description-section{
			.col-info{
				.title{
					font-size: 1.95rem;
				}

				.subcategory{
					font-size: 1.1rem;
				}

				.descr{
					font-size: 0.92rem;
					font-weight: 400;
					line-height: 1.1;
				}
			}
		}
	}
}
