#plans-page{
  padding-top: 15px;
	padding-bottom: 30px;

  .main-section{
    .col-title{
      .title-s-1{
        margin-bottom: 2px;
      }

      .subtext{
        color: #282828;
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
      }
    }

    .row-plans{
      margin-top: 24px;

      .col-plan{
        .hr-sep{
          margin-top: 0;
          margin-bottom: 0;
          border-top: 1px solid #dcd0e0;
        }

        .box-plan{
          display: block;
          height: 100%;
          color: #282828;
          text-align: center;
          border-radius: 2px;
          border: 1px solid #dcd0e0;

          .box-con{
            display: block;
            padding: 32px 18px;
          }

          .plan-name{
            margin-bottom: 4px;
            font-size: 1.70rem;
            font-weight: 700;
            text-transform: uppercase;
          }

          .box-descr-1{
            display: block;
            padding: 0px 0;
            margin-bottom: 6px;
            min-height: 60px;
            line-height: 1.15;
          }

          .box-from{
            display: block;
            margin-bottom: 12px;
            color: #767676;
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 1;
          }

          .price{
            font-size: 2.5rem;
            font-weight: 700;
          }

          .box-btns{
            display: block;
            padding: 0 20px;
            margin-top: 24px;

            .btn-select{
              display: block;
              padding: 15px 15px;
              color: #282828;
              cursor: pointer;
              font-size: 0.90rem;
              font-weight: 700;
              text-decoration: none !important;
              border: 1px solid #dcd0e0;
              @include transition(150ms);

              &:hover, &:focus, &:active{
                color: #ffffff;
                background-color: #282828;
                border-color: #282828;
              }
            }
          }

          .box-conditions{
            padding: 0 15px;
            text-align: left;
            line-height: 1.15;

            h6{
              font-size: 0.95rem;
              font-weight: 700;
            }

            ul{
              padding-left: 15px;
              margin-bottom: 0;
              font-size: 0.95rem;
            }

            strong{
              font-weight: 600;
            }
          }
        }

        &.orange{
          .hr-sep{
            border-top-color: #e6b490;
          }

          .box-plan{
            border-color: #e6b490;

            .box-con{
              .plan-name{
                color: $orange;
              }

              .box-btns{
                .btn-select{
                  color: $orange;
                  border-color: $orange;

                  &:hover{
                    color: #ffffff !important;
                    background-color: $orange2;
                  }
                }
              }

              .box-conditions{
                font-size: 0.95rem;

                h6{
                  color: $orange2;
                }
              }
            }
          }
        }

      }
    }

    @media screen and (max-width: 991px){
      .row-plans{
        .col-plan{
          margin-bottom: 20px;

          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }

}
