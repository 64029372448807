#easylovers-page{
  padding-top: 15px;
	padding-bottom: 30px;

  .main-section{
    .col-title{
      margin-bottom: 20px;

      .sub{
        color: #3e3e3e;
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 1.2;
        text-align: center;
      }

      @media screen and (min-width: 768px){
        .title-s-1{
          font-size: 3.0rem;
        }
      }
      @media screen and (max-width: 767px){
        .sub{
          font-size: 1.1rem;
        }
      }
    }

    .col-info{
      .article-sample-1{
        margin-top: 15px;
        margin-bottom: 15px;

  			.box{
  				position: relative;
  				display: flex;
  				flex-wrap: wrap;
  				flex: 0 0 100%;
  				max-width: 100%;
  				color: #767676;
  				text-decoration: none !important;
  				align-items: center !important;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);

  				&:after{
  					content: " ";
  					position: absolute;
  					bottom: 10px;
  					left: 0;
  					width: 100%;
  					height: 38px;
  					background: linear-gradient(0deg, rgba(255,255,255,1) 16%, rgba(255,255,255,0) 100%);
  					z-index: 6;
  				}
  			}

  			.col-image{
  				z-index: 7;
  				padding: 0;
  				flex: 0 0 276px;
  				max-width: 276px;
  				background-color: $soft-gray;

  				img{
  					width: 100%;
  				}
  			}

  			.col-text{
  				position: relative;
  				padding: 16px 15px;

  				.title{
  					margin-bottom: 10px;
  					color: #373a3c;
  					font-size: 1.55rem;
  					line-height: 1.1;
  					font-weight: 700;
  				}

  				.descr{
  					position: relative;
  					display: block;
  					max-height: 200px;
  					font-size: 0.98rem;
  					line-height: 1.4;
  					overflow: hidden;
  				}
  			}

  			@media screen and (min-width: 992px) and (max-width: 1399px){
  				.col-text{
  					.title{
  						font-size: 1.45rem;
  						margin-bottom: 5px;
  					}
  				}
  			}
  			@media screen and (min-width: 992px) and (max-width: 1199px){
  				.col-image{
  					flex: 0 0 250px;
  					max-width: 250px;
  				}

  				.col-text{
  					.title{
  						font-size: 1.30rem;
  					}
  					.descr{
  						max-height: 146px;
  						font-size: 0.9rem;
  					}
  				}
  			}
  			@media screen and (max-width: 767px){
          .box{
            &:after{
              display: none !important;
            }
          }
  				.col-image{
  					flex: 0 0 100%;
  					max-width: 100%;
  				}

  				.col-text{
  					.title{
  						margin-bottom: 5px;
  						font-size: 1.40rem;
  					}
            .descr{
              max-height: inherit;
            }
  				}
  			}
  		}
    }
  }

}
