// == Article container ==
.col-article-sample-1{
  padding: 0;
  @include transition(150ms);

  &:hover{
    background-color: $orange2;

    .box-article{
      color: #ffffff !important;
      text-decoration: none !important;
    }

    .box-descr{
      h5{
        color: #ffffff;
      }
    }
  }

  .box-article{
    position: relative;
    display: inline-block;
    padding: 20px;
    width: 100%;
    color: #4d4e4f;
    @include transition(150ms);
  }

  .box-image{
    display: inline-block;
    width: 100%;
    // max-height: 330px;
    background-color: rgba(0,0,0,0.1);
    overflow: hidden;

    img{
      width: 100%;
    }
  }

  .box-descr{
    position: relative;
    display: inline-block;
    padding: 12px 0 0 0;
    width: 100%;

    h5{
      position: relative;
      height: 41px;
      color: $orange;
      font-size: 1.05rem;
      font-weight: 600;
      overflow: hidden;
      @include transition(150ms);
    }

    .descr{
      position: relative;
      display: block;
      font-size: 0.90rem;
      line-height: 1.3;
      text-align: justify;
      max-height: 57px;
      overflow: hidden;
    }
  }

  &.sample-2{
    .box-article{
      padding: 15px 15px;
    }

    // .box-image{
    //   display: block;
    //   height: 220px;
    //   overflow: hidden;
    // }

    .box-descr{
      display: block;

      h5{
        margin-bottom: 4px;
        height: auto;
      }
      .descr{
        max-height: 38px;
      }
    }
  }
}
// == ==

// == Blog index ==
#blog-page{
  padding-top: 15px;
	padding-bottom: 30px;

  .articles-section{
    .col-page-title-t2{
      margin-bottom: 12px;
    }

    .col-form{
      form{
        position: relative;
        width: 100%;

        .form-control{
          border-color: $orange;
        }

        .btn-search{
          position: absolute;
          top: 0;
          right: 0;
          width: 100px;
          background-color: $orange;
          border-color: $orange;

          &:hover{
            background-color: $orange2;
            border-color: $orange2;
          }
        }
      }
    }

    .col-pagination-sample-1{
      margin-top: 15px;
    }
  }
}
// == ==

// == Article page (blog detail) ==
#article-page{
  padding-top: 15px;
	padding-bottom: 30px;

  .content-section{
    .col-page-title-t2{
      margin-bottom: 12px;
    }

    .col-article{
      .float-image{
        float: left;
        width: 520px;
        max-width: 100%;
        padding: 0 24px 24px 0;
      }

      .box-joss{

      }
      .image-s2{
        display: inline-block;
        width: 100%;
      }
      // .box-image{
      //   display: inline-block;
      //   margin-bottom: 3px;
      //   width: 100%;
      //
      //   img{
      //     width: 100%;
      //   }
      // }

      .title{
        color: $orange;
        font-size: 1.5rem;
        font-weight: 500;
      }

      .descr{
        display: inline-block;
        margin-top: 6px;
        width: 100%;
        color: #5d5d5d;
        font-size: 0.95rem;
        line-height: 1.3;

        a{
          color: $orange
        }

        ul,ol{
          padding-left: 19px;
        }

        img{
          max-width: 100% !important;
        }

        &.float{
          display: inline;
          width: inherit;
        }
      }

      @media screen and (max-width: 991px){
        .float-image{
          float: left;
          width: 330px;
          max-width: 100%;
          padding: 0 24px 24px 0;
        }
      }
      @media screen and (max-width: 575px){
        .float-image{
          float: inherit;
          width: 100%;
          max-width: 100%;
          padding: 0 0 18px 0;
        }
      }
    }

    .col-blog{
      .col-article-sample-1{
        &.sample-2{
          &:after{
            position: absolute;
            content: " ";
            bottom: -1px;
            left: 15px;
            width: calc(100% - 30px);
            border-bottom: 1px solid $orange2;
            @include transition(100ms);
          }
        }

        &:hover{
          &.sample-2{
            &:after{
              opacity: 0;
            }
          }
        }

        &:last-child{
          &.sample-2{
            &:after{
              display: none;
            }
          }
        }
      }

      @media screen and (max-width: 991px){
        display: none;
      }
    }
  }

}
// == ==
