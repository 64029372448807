body,html{
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	color: $gray;
}

body{
	background-color: #ffffff;

	>#__nuxt{
		max-width: 2450px;
		min-width: 350px;
		margin: 0 auto;
		background-color: #ffffff;
	}
}

p,h1,h2,h3,h4,h5,h6{
	margin-bottom: 0px;
}

// Container
.oversized-container{
	@media screen and (min-width: 992px){
		max-width: 98%;
	}
	@media screen and (min-width: 1630px){
		max-width: 1590px;
	}
}

// Backgrounds
.placed-backg{
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

// Remove outline
select:focus, select:active, select:hover, button:focus, button:active, button:hover,
input:focus, input:active, input:hover, textarea:focus, textarea:active, textarea:hover{
  box-shadow: none !important;
  outline: none !important;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow:none !important;
}


// Extra padding top cuando la pagina sea otra distinta al Home(index)
.extra-padding-top{
	@media screen and (min-width: 768px){
		padding-top: 55px;
	}
}

/* Remove Arrows/Spinners */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
