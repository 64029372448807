#faqs-page{
  padding-top: 15px;
	padding-bottom: 30px;

  .main-section{
    .col-title{
      margin-bottom: 5px;
    }

    .col-faqs{
      min-height: 58vh;

      .card{
        border: 0;

        .card-header{
          position: relative;
          padding: 0;
          border: 0;
          background-color: transparent !important;

          .question{
            position: relative;
            display: block;
            color: $blue;
            font-size: 1.1rem;
            font-weight: 700;
            text-decoration: none !important;
            cursor: pointer;

            &:after{
              content: "\f077";
              display: inline-block;
              position: absolute;
              top: 2px;
              right: 0;
              color: $blue;
              font-family: "Font Awesome 5 Pro";
              font-size: 1.2rem;
              font-weight: 300;
              line-height: 1;
            }

            &.collapsed{
              &:after{
                content: "\f078" !important;
              }
            }

            &:hover{
              color: #0672a2;
            }
          }
        }

        .card-body{
          padding: 0.5rem 0;
        }
      }
    }

  }

}
