#jobs-page{
  padding-top: 15px;
	padding-bottom: 30px;

  .main-section{
    .col-title{
    }

    .col-job{
      margin-top: 15px;
      margin-bottom: 15px;

      .box{
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

        .box-image{
          display: block;
          padding: 16px;

          img{
            width: 100%;
            background-color: $soft-gray;
          }
        }

        .box-info{
          display: block;
          padding: 0 16px 72px 16px;

          .title{
            margin-bottom: 4px;
            color: #565656;
            font-size: 1.25rem;
            font-weight: 700;
          }

          .subtitle{
            min-height: 36px;
            color: $blue;
            font-size: 0.98rem;
            font-weight: 600;
            line-height: 1.15;
          }

          .descr{
            height: 124px;
            font-size: 0.92rem;
            line-height: 1.20;
            overflow: hidden;
          }

          .box-buttons{
            position: absolute;
            display: block;
            padding: 12px 16px 16px 16px;
            bottom: 16px;
            left: 0;
            width: 100%;
            height: 56px;

            a{
              padding: 9px 15px;
            }
          }
        }
      }
    }
  }

}
