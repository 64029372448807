#home-page{
	position: relative;

	// == Banners ==
	.banner-section{
		position: relative;

		.swiper{
			position: relative;

			.swiper-slide{
				img{
					width: 100%;
				}
			}

			.swiper-button-prev, .swiper-button-next{
				&:after{
					color: #ffffff !important;
				}
			}
		}

		.s-desktop{}
		.s-mobile{
			display: none;
		}

		@media screen and (max-width: 991px){
			.s-desktop{
				display: none;
			}
			.s-mobile{
				display: block;
			}
		}
	}
	// == ==

	// == Visits ==
	.benefits-section{
		padding: 25px 15px;
		background-color: #ededed;

		.col-bg-button{
			margin-top: 10px;
			margin-bottom: 10px;

			a{
				flex: 0 0 100%;
				max-width: 100%;
				padding: 8px 20px;
				height: 115px;
				color: #ffffff;
				cursor: pointer;
				text-decoration: none !important;
				border-radius: 60px;
				background-color: #999;

				>div{
					display: inline-block;
					width: 100%;
					font-size: 21px;
					text-align: center;

					.title{
						font-size: 24px;
						font-weight: 600;
					}
				}

				&.trial{
					background-color: $blue;

					&:hover{
						background-color: $blue2;
					}
				}

				&.visit{
					background-color: $orange;

					&:hover{
						background-color: $orange2;
					}
				}

				&.easylover{
					background-color: $pink;

					&:hover{
						background-color: $pink2;
					}
				}
			}
		}

		@media screen and (max-width: 1199px){
			.col-bg-button{
				a{
					>div{
						font-size: 16px;

						.title{
							font-size: 20px;
							font-weight: 500;
							line-height: 1.1;
						}
					}
				}
			}
		}
		@media screen and (max-width: 767px){
			padding: 15px 15px;

			.col-bg-button{
				a{
					height: 82px;

					>div{
						font-size: 15px;

						.title{
							font-size: 19px;
							font-weight: 500;
							line-height: 0.9;

							&.easylover{
								font-size: 23px;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 400px){
			.col-bg-button{
				a{
					padding: 16px 15px;
					height: inherit;
					min-height: 62px;

					>div{
						font-size: 13px;

						.title{
							font-size: 16px;
							line-height: 0.9;
						}
					}
				}
			}
		}
	}
	// == ==

	// == Samples ==
	.samples-section{
		padding-top: 15px;
		padding-bottom: 38px;
		background-color: #ededed;

		&.facilities{
			background-color: #ffffff !important;
		}

		.col-title{}

		.col-image{
			margin-top: 10px;

			.swiper{
				.swiper-slide{
					.box{
						display: block;
						background-color: #ffffff;

						img{
							width: 100%;
						}

						&.cursor-pointer{
							cursor: pointer;
							@include transition(150ms);

							&:hover{
								opacity: 0.90;
							}
						}
					}
				}

				.swiper-button-prev, .swiper-button-next{
					&:after{
						color: #ffffff;
						font-size: 35px;
						font-weight: 700;
					}
				}
				.swiper-button-prev{
					left: 16px;
				}
				.swiper-button-next{
					right: 16px;
				}
			}
		}

		@media screen and (max-width: 1499px){
			.col-promos{
				margin-top: 5px;
			}
		}
		@media screen and (max-width: 575px){
			.col-image{
				margin-top: 0;
			}
		}
	}
	// == ==

	// == Classes ==
	.classes-section{
		padding-top: 20px;
		padding-bottom: 32px;
		line-height: 1.1;
		background-color: #ededed;

		.col-title{
			margin-bottom: 24px;
			color: $gray;
			text-align: center;

			.title-s-1{
				margin-bottom: 2px;
			}

			p{
				font-size: 1.05rem;
				font-weight: 500;
			}
		}

		.col-gallery{
			.swiper{
				.swiper-slide{
					.class-sample-1{}
				}

				.swiper-button-prev, .swiper-button-next{
					&:after{
						color: #ffffff;
						font-size: 45px;
						font-weight: 300;
					}
				}
				.swiper-button-prev{
					left: 16px;
				}
				.swiper-button-next{
					right: 16px;
				}
			}
		}

		.col-button{
			margin-top: 28px;
			text-align: center;

			a{
				display: block;
				padding: 20px 15px;
				color: #ffffff;
				cursor: pointer;
				font-size: 1.5rem;
				letter-spacing: 1px;
				font-weight: 700;
				text-decoration: none !important;
				border-radius: 36px;
				background-color: $green;

				&:hover{
					background-color: $green2;
				}
			}
		}

		@media screen and (max-width: 991px){
			.col-title{
				p{
					font-size: 1.0rem;
				}
			}

			.col-button{
				a{
					padding: 16px 15px;
					font-size: 1.1rem;
					font-weight: 600;
				}
			}
		}
	}
	// == ==

	// == Blog ==
	.blog-section{
		padding-top: 25px;
		padding-bottom: 38px;

		.col-title{
			margin-bottom: 12px;
		}

		.col-articles{
			.swiper{
				.swiper-button-prev, .swiper-button-next{
					width: 30px;
					border-radius: 3px;
					background-color: #ffffff;
					box-shadow: 0 0 3px rgba(0,0,0,0.6);

					&:after{
						color: $pink;
						font-size: 23px;
						font-weight: 700;
					}
				}

				.swiper-button-disabled{
					display: none;
				}
			}
		}

		.article-sample-1{
			.box{
				position: relative;
				display: flex;
				flex-wrap: wrap;
				flex: 0 0 100%;
				max-width: 100%;
				color: #767676;
				text-decoration: none !important;
				align-items: center !important;

				&:after{
					content: " ";
					position: absolute;
					bottom: 10px;
					left: 0;
					width: 100%;
					height: 38px;
					background: linear-gradient(0deg, rgba(255,255,255,1) 16%, rgba(255,255,255,0) 100%);
					z-index: 6;
				}

				&:hover{
					opacity: 0.85;
				}
			}

			.col-image{
				z-index: 7;
				padding: 0;
				flex: 0 0 276px;
				max-width: 276px;
				background-color: $soft-gray;

				img{
					width: 100%;
				}
			}

			.col-text{
				position: relative;
				padding: 16px 15px;

				.title{
					margin-bottom: 10px;
					color: #373a3c;
					font-size: 1.55rem;
					line-height: 1.1;
					font-weight: 700;
				}

				.descr{
					position: relative;
					display: block;
					max-height: 200px;
					font-size: 0.98rem;
					line-height: 1.4;
					overflow: hidden;
				}
			}

			@media screen and (min-width: 992px) and (max-width: 1399px){
				.col-text{
					.title{
						font-size: 1.45rem;
						margin-bottom: 5px;
					}
				}
			}
			@media screen and (min-width: 992px) and (max-width: 1199px){
				.col-image{
					flex: 0 0 250px;
					max-width: 250px;
				}

				.col-text{
					.title{
						font-size: 1.30rem;
					}
					.descr{
						max-height: 146px;
						font-size: 0.9rem;
					}
				}
			}
			@media screen and (max-width: 767px){
				.col-image{
					flex: 0 0 100%;
					max-width: 100%;
				}

				.col-text{
					padding-left: 0;
					padding-right: 0;

					.title{
						margin-bottom: 5px;
						font-size: 1.40rem;
					}
				}
			}
		}
	}
	// == ==

	// == Videos ==
	.videos-section{
		padding-top: 20px;
		padding-bottom: 20px;
		background-color: #ededed;
	}
	// == ==
}
