// == Style 1 ==
.modal-s1{
  .modal-content{
    padding-top: 6px;
    padding-bottom: 6px;
    border: 0;
    border-radius: 1px;
    box-shadow: 0 0 5px rgba(0,0,0,0.15);
    background-color: $orange;

    .modal-header{
      position: relative;
      padding: 0;
      border: 0;

      .title{
        display: none;
      }

      .close{
        position: absolute;
        padding: 0.0rem 0.7rem;
        margin: 0;
        top: 7px;
        right: 3px;
        color: #ffffff;
        line-height: 1;
        font-size: 2.4rem;
        font-weight: 300;
        text-shadow: none;
        opacity: 1;
        z-index: 20;
      }
    }

    .modal-body{
      color: #ffffff;

      a{
        color: #ffffff;
      }

      .box-top{
        display: block;
        padding-bottom: 10px;

        .modal-title{
          text-align: center;
          font-size: 1.6rem;
          font-weight: 600;
          line-height: 1.2;
        }
      }

      .box-pdf-sample{
        .col-image{
          flex: 0 0 145px;
          max-width: 145px;

          .image{
            display: block;
            // height: 100%;
            border-radius: 3px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $soft-gray;
            background-size: 88%;

            img{
              width: 100%;
            }
          }
        }

        .col-info{
          h6{
            margin-bottom: 8px;
            font-size: 0.90rem;
            line-height: 1.05;

            strong{
              // display: block;
            }
          }
        }
      }
    }
  }
}
// == ==

.modal-schedule{
  .modal-dialog{
    max-width: 668px;
  }

  .modal-body{
    .content{
      position: relative;
      display: inline-block;
      width: 100%;

      .col-table{
        padding: 3px 22px 1px 22px;

        .img-table{
          width: 100%;
        }
      }
    }
  }
}

.modal-plan{
  .modal-body{
    .box-target-content{
      .custom-f-group-1{
        .target-group{
          .custom-radio{
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 10px 15px;
            margin: 0;

            .custom-control-label{
              @include flex-center-xy();
              flex: 0 0 100%;
              max-width: 100%;
              min-height: 62px;
              padding: 0;
              text-align: center;
              border: 2px solid #fff;
              border-radius: 8px;
              cursor: pointer;

              &:before, &:after{
                display: none !important;
              }

              .box-target{
                display: inline-block;
                padding: 12px 15px;
                width: 100%;
                font-size: 1.25rem;
                font-weight: 600;
                line-height: 1.20;
              }
            }

            .custom-control-input:checked ~ .custom-control-label{
              background: $green;
              border-color: $green;
              box-shadow: 0 0 9px rgba(0, 0, 0, 0.10);
            }
          }
        }

        .group-txt-sm{
          .custom-radio{
            .custom-control-label{
              .box-target{
                font-size: 0.95rem;
              }
            }
          }
        }

        @media screen and (max-width: 575px){
          .target-group{
            .custom-radio{
              .custom-control-label{
                .box-target{
                  font-size: 1.10rem;
                }
              }
            }
          }

          .group-txt-sm{
            .custom-radio{
              flex: 0 0 100%;
              max-width: 100%;

              .custom-control-label{
                .box-target{
                  font-size: 1.0rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-s2{
  .modal-content{
    border: 0;
    border-radius: 1px;
    box-shadow: 0 0 5px rgba(0,0,0,0.15);

    .modal-header{
      position: relative;
      padding: 4px 22px 5px 22px;
      border: 0;
      border-radius: 0;
      background-color: $blue;

      .modal-title{
        color: #ffffff;
        font-size: 1.10rem;
        font-weight: 600;
        text-transform: uppercase;
      }

      .close{
        position: absolute;
        padding: 0.0rem 0.7rem;
        margin: 0;
        top: 1px;
        right: 4px;
        color: #ffffff;
        line-height: 1;
        font-size: 2.0rem;
        font-weight: 500;
        text-shadow: none;
        opacity: 1;
        z-index: 20;
      }
    }

    .modal-body{
      padding: 1.1rem 1.5rem 1.4rem 1.5rem;
      color: #464545;

      .title{
        margin-bottom: 6px;
        color: #282828;
        font-size: 1.15rem;
        font-weight: 600;
      }

      .box-description{
        line-height: 1.2;
        text-align: justify;

        a{
          color: $orange;
        }

        ul, ol{
          padding-left: 15px;
        }
      }
    }
  }
}
