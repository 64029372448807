.custom-f-group-1{
  label, legend{
    padding-bottom: 0;
    padding-left: 8px;
    font-size: 0.95rem;
    font-weight: 300;
  }

  >div{
    .form-control, .custom-select{
      border-radius: 10px;
      border-color: #979797;
    }

    .custom-select{
      option{
        color: #495057;

        &:first-child{
          color: #899299 !important;
        }
      }

      &.not-filled{
        color: #6c757d;
      }
    }
  }
}

.custom-f-group-2{
  label, legend{
    padding-bottom: 0;
    padding-left: 8px;
    color: $orange;
    font-size: 1.2rem;
    font-weight: 800;
  }

  >div{
    @include placeholder-color(#899299);

    .form-control, .custom-select{
      color: #495057;
      font-weight: 600;
      border-radius: 10px;
      border-color: #767676;
    }

    .custom-select{
      option{
        color: #495057;

        &:first-child{
          color: #899299 !important;
        }
      }

      &.not-filled{
        color: #899299;
      }
    }

    textarea{
      resize: none;
    }
  }
}
