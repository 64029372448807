#branch-office-page{
  padding-top: 15px;
	padding-bottom: 30px;

  .basic-info-section{
    margin-bottom: 35px;

    .col-title{
      margin-bottom: 8px;
    }

    .col-image{
      .image{
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: $soft-gray;
      }
    }

    .col-info{
      min-height: 340px;
      line-height: 1.4;
      // font-size: 1.05rem;

      .name{
        font-size: 1.80rem;
        font-weight: 700;
        text-transform: uppercase;
      }

      .subtitle{
        font-size: 1.15rem;
        font-weight: 600;
        text-transform: uppercase;
      }

      a{
        color: $gray;
      }
    }

    @media screen and (max-width: 991px){
      margin-bottom: 15px;

      .col-title{
        margin-bottom: 5px;
      }

      .col-image{
        margin-bottom: 15px;
        height: 250px;
      }

      .col-info{
        .name{
          font-size: 1.6rem;
        }

        .subtitle{
          font-size: 1.05rem;
          line-height: 1.0;
        }
      }
    }
  }

  .map-section{
    position: relative;
    margin-bottom: 35px;
    width: 100%;

    iframe{
      width: 100%;
      height: 400px;
      border: 0;
    }

    @media screen and (max-width: 991px){
      margin-bottom: 20px;
    }
  }

  .gallery-section{
    >.row{
      margin-left: -8px;
      margin-right: -8px;
    }

    .col-image{
      padding: 8px;

      .img{
        display: block;
        background-color: $soft-gray;
        cursor: pointer;

        &:hover{
          opacity: 0.85;
        }
      }
    }

    @media screen and (min-width: 1600px){
      .col-image{
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }

}
