#facilities-page{
  padding-top: 15px;
	padding-bottom: 30px;

  .main-section{
    .col-title{}

    .col-videos{
      margin-top: 5px;

      .swiper{
        .swiper-slide{
          iframe{
            width: 100%;
            height: 69vh;
          }
        }

        .swiper-button-prev{
          left: 20px;
        }
        .swiper-button-next{
          right: 20px;
        }
        .swiper-button-prev, .swiper-button-next{
          width: 46px;
          height: 46px;
          border-radius: 50%;
          background-color: $green;
          // opacity: 1;

          &:after{
            color: #ffffff;
            font-size: 1.6rem;
            font-weight: 700;
          }
        }
      }
    }

    .col-video{
      margin-top: 5px;

      iframe{
        width: 100%;
        height: 69vh;
      }
    }

    .col-gallery{
      .swiper{
        .swiper-slide{
          .box{
            cursor: pointer;

            &:hover{
              opacity: 0.9;
            }
          }
        }

        .swiper-button-prev, .swiper-button-next{
          color: #ffffff;
        }
      }
    }

    @media screen and (max-width: 575px){
      .col-videos{
        .swiper{
          .swiper-slide{
            iframe{
              height: 55vh;
            }
          }

          .swiper-button-prev{
            left: 14px;
          }
          .swiper-button-next{
            right: 14px;
          }

          .swiper-button-prev, .swiper-button-next{
            width: 40px;
            height: 40px;

            &:after{
              font-size: 1.3rem;
            }
          }
        }
      }
      .col-video{
        iframe{
          height: 60vh;
        }
      }
    }
  }

}
