.title-s-1{
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 2.05rem;
  font-weight: 800;
  line-height: 1.05;
  text-align: center;

  &.txt-upp{
    font-size: 1.9rem;
  }

  @media screen and (max-width: 767px){
    font-size: 1.95rem;

    &.txt-upp{
      font-size: 1.70rem;
    }
  }
}
